import * as React from "react"
import styled from "styled-components"
import ContactInfo from "../contactInfo"
import emailjs from "emailjs-com"

const SidebarWrap = styled.div`
  background: #09425a;
  color: white;
  padding: 1.5rem;
  text-align: center;
  height: auto;

  hr {
    margin: 1rem 0;
  }

  @media (min-width: 768px) {
    padding: 3rem;
  }
  @media (min-width: 1024px) {
    padding: 2rem;
    min-width: 320px;
  }
`

const Form = styled.div`
  h5 {
    margin-bottom: 0.5rem;
  }
  button {
    margin-top: 0.5rem;
    background-color: #ef4565;
    width: 100%;
    padding: 0.5rem 0;
  }
  button:hover {
    background-color: #ff5675;
  }

  @media (min-width: 768px) {
    width: 80%;
    margin: 0 auto;

    button {
      width: 100%;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (min-width: 1024px) {
    width: 96%;
    margin: 0 auto;
  }
`

const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 0.4rem;

  label {
    margin-bottom: 2px;
  }
  input,
  textarea {
    padding: 7px;
    font-size: 1rem;
    border-radius: 3px;
    border: none;
  }
  textarea {
    height: 6rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-family: inherit;
  }

  @media (min-width: 768px) {
    max-width: 500px;
    width: 100%;
    margin: 0 auto 0.6rem;
  }
  @media (min-width: 1024px) {
  }
`

const Sidebar = () => {
  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        `${process.env.EMAILJS_SERVICE}`,
        `${process.env.EMAILJS_TEMPLATE}`,
        e.target,
        `${process.env.EMAILJS_USER}`
      )
      .then(
        result => {
          console.log(result.text)
          alert("Message was sent successfully! We will be in contact soon.")
        },
        error => {
          console.log(error.text)
        }
      )
  }

  return (
    <SidebarWrap>
      <h5>Contact Us</h5>
      <hr />
      <ContactInfo />

      <hr />
      <Form>
        <h5>
          Let’s get started on your road to a more empowered healthcare
          strategy!
        </h5>
        <form className="contact-form" onSubmit={sendEmail}>
          <input type="hidden" name="contact_number" />
          <FormInput>
            <label htmlFor="name">Name</label>
            <input type="text" name="name" required={true} />
          </FormInput>
          <FormInput>
            <label htmlFor="email">Email</label>
            <input type="email" name="email" required={true} />
          </FormInput>
          <FormInput>
            <label htmlFor="phone">Phone</label>
            <input type="text" name="phone" required={true} />
          </FormInput>
          <FormInput>
            <label htmlFor="message">Message</label>
            <textarea name="message" required={true}></textarea>
          </FormInput>
          <button type="submit">Submit</button>
        </form>
      </Form>
    </SidebarWrap>
  )
}

export default Sidebar
