import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const ContactInfoWrap = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .gatsby-image-wrapper {
    margin-right: 8px;
  }
`
const ContactInfoItem = styled.div`
  margin: 0.5rem 0;
`

const ContactInfo = () => (
  <ContactInfoWrap>
    <ContactInfoItem>
      <StaticImage
        src="../images/phone-icon.png"
        width={30}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="A Gatsby astronaut"
      />
      <h6>(760) 585 - 4616</h6>
    </ContactInfoItem>
    <ContactInfoItem>
      <StaticImage
        src="../images/fax-icon.png"
        width={30}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="A Gatsby astronaut"
      />
      <h6>(760) 444 - 1858</h6>
    </ContactInfoItem>
    <ContactInfoItem>
      <StaticImage
        src="../images/location-icon.png"
        width={30}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="A Gatsby astronaut"
      />
      <h6>
        5814 Van Allen Way,
        <br />
        Carslbad, CA 92008, Suite 212
      </h6>
    </ContactInfoItem>
    <ContactInfoItem>
      <StaticImage
        src="../images/hours-icon.png"
        width={30}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="A Gatsby astronaut"
      />
      <h6>
        MON, TUES, THURS, FRI
        <br />
        08:00am - 04:00 pm
      </h6>
    </ContactInfoItem>
  </ContactInfoWrap>
)

export default ContactInfo
