import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const TestimonialWrap = styled.div`
  padding: 2rem 0;

  display: grid;
  grid-template-areas:
    "title"
    "image"
    "info";
  background: #09425a;
  color: white;

  h2 {
    grid-area: title;
    text-align: center;
  }
  .testimonial-person {
    grid-area: image;
    width: 40%;
    margin: 1rem auto;
    border-radius: 5px;
    border: none;
  }

  @media (min-width: 768px) {
    grid-template-areas:
      "title  title"
      "image  info";
    grid-template-columns: 1fr, 3fr;
    grid-gap: 0 1rem;
    padding: 2rem 3rem;

    h2 {
      width: 80%;
      margin: 0 auto 1rem;
    }
    .testimonial-person {
      width: 100%;
      height: 400px;
      margin: 0;
      align-self: center;
    }
    .gatsby-image-wrapper {
    }
  }
  @media (min-width: 1024px) {
    max-width: 960px;
    margin: 0 auto 3rem;
    grid-template-areas:
      "title image"
      "info image";

    h2 {
      text-align: left;
      width: 100%;
      margin: 0 auto 1rem 0;
      align-self: end;
    }
  }
`

const TestimonialInfo = styled.div`
  padding: 0 1.6rem;
  grid-area: info;
  display: grid;
  grid-template-areas:
    "image topText"
    "image bottomText"
    "image testimonialName";
  grid-template-columns: 1fr 3fr;
  .grayQuote {
    grid-area: image;
    width: 100%;
  }
  .topText {
    grid-area: topText;
  }
  .bottomText {
    grid-area: bottomText;
  }
  h5 {
    grid-area: testimonialName;
  }
  p {
    font-size: 0.9rem;
  }

  @media (min-width: 768px) {
    padding: 0;
    grid-template-columns: none;
    grid-template-areas:
      "image "
      " bottomText "
      " testimonialName ";
  }
  @media (min-width: 1024px) {
    .topText,
    .bottomText {
      width: 90%;
      margin-right: auto;
      margin-bottom: 0;
      max-width: 700px;
    }
  }
`

const Testimonial = () => (
  <TestimonialWrap>
    <h2>What Our Clients Are Saying</h2>
    <TestimonialInfo>
      <div className="grayQuote">
        <StaticImage
          src="../images/gray-quote.png"
          width={100}
          height={100}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Gray quote"
        />
      </div>
      <div>
        <p className="topText">
          A few years ago, I met Dr. Juergen Winkler seeking help for a relative
          whose needs were not being met by her conventional clinicians. I was
          so impressed by the results Dr. Winkler’s patients were getting that I
          decided to consult him for my own health issues.
        </p>
        <p>
          In the time since I met him, I have observed that Dr. Winkler
          continually invests in his own continuing education to combine cutting
          edge methods of traditional and integrative medicine to treat the
          whole individual - not just disease. His dependence on Christ as the
          ultimate healer enables him to approach the art and science of healing
          with confidence and humility.
        </p>
        <p className="bottomText">
          My experience with Dr. Winkler and his staff at Quantum Functional
          Medicine has enhanced my overall well-being and restored my physical
          vitality. My quality of life is much better under his care. Working in
          the health care field myself, I have an even greater appreciation for
          Dr. Winkler’s comprehensive approach.
        </p>
      </div>
      <h5>Joan Giroux</h5>
    </TestimonialInfo>

    <StaticImage
      src="../images/joan-testimonial.jpg"
      width={400}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Joan Giroux Testimonial"
      className="testimonial-person"
    />
  </TestimonialWrap>
)

export default Testimonial
