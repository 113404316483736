import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Sidebar from "../components/Sidebar"
import Testimonial from "../components/testimonial"

import BannerBG from "../images/banner-vector.png"

const BannerWrapper = styled.section`
  background-image: url(${BannerBG});
  background-position: top;
  background-size: auto 60%;
  background-repeat: no-repeat;
  margin: 1.45rem 0;
  @media (min-width: 768px) {
    background-size: contain;
  }
`

const Banner = styled.section`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  .gatsby-image-wrapper {
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    width: 85%;
    margin: 0 auto;
    background-size: contain;
    padding: 0 3rem;
    .gatsby-image-wrapper {
      margin-left: 1rem;
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    background-size: contain;
    padding: 2rem 0;
    width: 70%;
    max-width: 960px;
    margin: 0 auto;
  }
  @media (min-width: 1248px) {
    width: 60%;
    margin: 0 auto;
  }
`

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-bottom: 0.5rem;
    text-align: center;
  }
  h5 {
    text-align: center;
    width: 80%;
    margin: 0 auto 1rem;
  }
  button {
    margin: 1rem 0 1.5rem;
  }

  @media (min-width: 768px) {
    width: 70%;
    align-items: flex-start;
    h1 {
      font-size: 2.5rem;
      text-align: left;
    }
    h5 {
      text-align: left;
      font-size: 1.1rem;
      width: clamp(45, 100%, 75ch);
      margin: 0 auto 1rem 0;
    }
  }
  @media (min-width: 1024px) {
    h1 {
      font-size: 3.5rem;
    }
    h5 {
      text-align: left;
      font-size: 1.333rem;
    }
    .gatsby-image-wrapper {
      margin: 0;
      width: 300px;
    }
  }
`

const Awards = styled.section`
  background-color: #09425a;
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .gatsby-image-wrapper {
    margin: 0 1rem;
  }

  @media (min-width: 768px) {
    .gatsby-image-wrapper {
      margin: 0 2rem;
    }
  }
  @media (min-width: 1024px) {
    .gatsby-image-wrapper {
      margin: 0 2rem;
    }
  }
`

const SidebarSectionWrapper = styled.section`
  @media (min-width: 768px) {
  }
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem 0;
    width: 90%;
    margin: 0 auto;
  }
`

const Mission = styled.div`
  margin: 2rem 0;

  h2 {
    text-align: center;
    margin-bottom: 0.5rem;
  }
  p {
    padding: 0 1.6rem;
  }

  @media (min-width: 768px) {
    h2 {
      text-align: left;
      padding: 0 1.6rem;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (min-width: 1024px) {
    h2 {
      text-align: left;
      padding: 0;

      margin-left: 0;
    }
    p {
      width: 90%;
      padding: 0;
      margin-left: 0;
      margin-right: auto;
    }
  }
`

const Categories = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  padding: 0 1rem;
  margin: 1.5rem 0 0;

  .block-dark {
    background-color: ${props => props.theme.colors.secondary};
  }
  .block-dark:hover {
    background-color: #778799;
  }

  .block-light {
    background: ${props => props.theme.colors.lightGray};
  }
  .block-light:hover {
    background: #ecebeb;
  }
  .block-dark,
  .blocklight {
    transition: all 0.3s ease;
  }

  @media (min-width: 768px) {
    grid-gap: 1.5rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1024px) {
    width: 90%;
    padding-left: 0;
    margin-left: 0;
  }
`
const CategoryBlock = styled(Link)`
  height: 100%;
  padding: 0.5rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  .gatsby-image-wrapper {
    margin: 2rem 0 0;
  }

  @media (min-width: 768px) {
    padding: 1rem;
    .gatsby-image-wrapper {
      margin: 6rem 0 0;
      transform: scale(1.5);
      transform-origin: bottom left;
    }
  }
  @media (min-width: 1024px) {
  }
`

const Spotlight = styled.section`
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (min-width: 768px) {
    width: 90%;
    margin: 2rem auto;
    display: grid;
    grid-template-areas:
      "drImage drContent"
      "stats stats";
    .gatsby-image-wrapper {
      grid-area: drImage;
    }
  }
  @media (min-width: 1024px) {
    margin: 4rem auto 6rem;
    grid-template-areas:
      "drImage drContent"
      "drImage stats";
    max-width: 960px;
    overflow: hidden;
    .gatsby-image-wrapper {
      width: 350px;
      height: 400px;
      align-self: center;
    }
    .spotlight-bg {
      background: #e0e0e0;
      position: absolute;
      right: -10%;
      width: 90%;
      height: 115%;
      z-index: -1;
      margin: 2rem 0;
    }
  }
`
const SpotlightContent = styled.div`
  padding: 0 1.6rem;

  h2 {
    font-size: 1.7rem;
    text-align: center;
    margin: 1rem 0;
  }

  @media (min-width: 768px) {
    grid-area: drContent;

    h2 {
      text-align: left;
    }
  }
  @media (min-width: 1024px) {
    p {
      width: 90%;
      max-width: 800px;
    }
  }
`
const Stats = styled.div`
  margin: 3rem 0 1rem;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem 1rem;

  @media (min-width: 768px) {
    grid-area: stats;
    margin: 3rem auto 1rem;
  }
  @media (min-width: 1024px) {
    max-width: 700px;
    margin: 3rem 1.6rem 1rem;
  }
`
const StatsItem = styled.div`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 3px;
  padding: 0.5rem;
  h3 {
    color: #09425a;
  }
  h5 {
    font-size: 1.2rem;
  }

  @media (min-width: 768px) {
    min-width: 300px;
    min-height: 110px;
  }
  @media (min-width: 1024px) {
    padding: 1.5rem;
    min-width: 200px;
  }
`

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <BannerWrapper>
      <Banner>
        <BannerContent>
          <h1>Patient Empowered Healthcare</h1>
          <h5>
            Quantum Functional Medicine is a center for health and rejuvenation.
          </h5>
          <Link to="/contact" className="btn">
            Get Started
          </Link>
        </BannerContent>
        <StaticImage
          src="../images/dr-winkler.jpg"
          width={260}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Dr. Winkler"
        />
      </Banner>
    </BannerWrapper>

    <Awards>
      <StaticImage
        src="../images/award-opencare.png"
        width={70}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Open Care Award"
      />
      <StaticImage
        src="../images/award-discover.png"
        width={70}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Open Care Award"
      />
      <StaticImage
        src="../images/award-neicertified.png"
        width={70}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Open Care Award"
      />
    </Awards>

    <SidebarSectionWrapper>
      <Mission>
        <div>
          <h2>Our Mission</h2>
          <p>
            Functional medicine is a systems oriented approach specific to each
            individual. It involves a partnership of the patient and
            practitioner to address underlying causes of disease.
          </p>
          <p>
            Functional medicine addresses the whole person with regards to their
            individual genetics, environmental exposures, life style factors,
            spiritual, emotional and physical makeup. Each of these factors are
            an influence in chronic disease and our goal is to address each of
            these in order to bring about health and vitality.
          </p>
        </div>
        <Categories>
          <CategoryBlock to="/services" className="block-light">
            <StaticImage
              src="../images/services.png"
              width={60}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Services icon"
              layout="constrained"
            />
            <h4>Services</h4>
          </CategoryBlock>
          <CategoryBlock to="/media" className="block-dark">
            <StaticImage
              src="../images/media.png"
              width={60}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Media icon"
              layout="constrained"
            />
            <h4>Media</h4>
          </CategoryBlock>
          <CategoryBlock to="/staff" className="block-dark">
            <StaticImage
              src="../images/staff.png"
              width={60}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Staff icon"
              layout="constrained"
            />
            <h4>Staff</h4>
          </CategoryBlock>
          <CategoryBlock to="/contact" className="block-light">
            <StaticImage
              src="../images/contact.png"
              width={60}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Contact icon"
              layout="constrained"
            />
            <h4>Contact</h4>
          </CategoryBlock>
        </Categories>
      </Mission>

      <Sidebar />
    </SidebarSectionWrapper>

    <Spotlight>
      <StaticImage
        src="../images/dr-winkler2.jpg"
        width={260}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Dr. Winkler"
      />
      <SpotlightContent>
        <h2>Juergen Winkler, M.D.</h2>
        <p>
          Dr. Juergen Winkler, MD is a Integrative Medicine Specialist in
          Carlsbad, CA and has over 33 years of experience in the medical field.
        </p>
        <p>
          {" "}
          He graduated from University Of Maryland School Of Medicine medical
          school in 1988.
        </p>
      </SpotlightContent>
      <Stats>
        <StatsItem>
          <h3>300+</h3>
          <h5>Patients with reduced pain</h5>
        </StatsItem>
        <StatsItem>
          <h3>9</h3>
          <h5>Years serving the Carlsbad community</h5>
        </StatsItem>
        <StatsItem>
          <h3>8</h3>
          <h5>Experienced staff members</h5>
        </StatsItem>
        <StatsItem>
          <h3>33+</h3>
          <h5>Years of functional medicine experience.</h5>
        </StatsItem>
      </Stats>
      <div className="spotlight-bg"></div>
    </Spotlight>

    <Testimonial />
  </Layout>
)

export default IndexPage
